// src/components/FlipNotification.js
import React, { useState, useEffect } from 'react';
import { Flipper, Flipped } from 'react-flip-toolkit';


const notifications = [
  "🎁 Airdrop: 1.11.2024 🎁",
  "🚀 Visit Qvrse Station! 🚀",
    "🚀 DEX launch soon! 🚀",
    "🎉 Airdrop incoming! 🎉",
    "📚 Read the FAQ! 📚",
    "🚀 DEX launch TBA! 🚀",
    "📢 Join Telegram! 📢",
    "🎁 Airdrop alert! 🎁",
    "💬 X account we have. 💬",
  ];
  

const FlipNotification = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % notifications.length);
    }, 5000); // Change notification every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <Flipper flipKey={index}>
      <Flipped flipId="notification">
        <div className="notification">
          {notifications[index]}
        </div>
      </Flipped>
    </Flipper>
  );
};

export default FlipNotification;
